import * as React from 'react';

import { ICoreContext, Image } from '@msdyn365-commerce/core';
import { IGridSettings } from '@msdyn365-commerce/core-internal';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { getFallbackImageUrl, getProductUrlSync } from '@msdyn365-commerce-modules/retail-actions';

interface ISliderCard {
  button_text: string;
  category?: string;
  context: ICoreContext;
  enableDescription?: boolean;
  enableLocation?: boolean;
  enablePrice?: boolean;
  gridSettings: IGridSettings;
  product: SimpleProduct;
  mobileMode: boolean;
}

/**
 *
 * EcommContentTemplate1View component
 * @extends {React.PureComponent<ISliderCard>}
 */
export class SliderCard extends React.PureComponent<ISliderCard> {
  public state: any = { hasMounted: false };

  public componentDidMount(): void {
    if (!this.state.hasMounted) {
      this.setState({ hasMounted: true });
    }
  }

  public render(): JSX.Element | null {
    if (!this.state.hasMounted) {
      return null;
    }

    if (this.props.mobileMode) {
      return (
        <div className='ecomm-product-slider-card' onClick={this._onClick}>
          {this._renderImage()}
          {this._renderInfo()}
        </div>
      );
    }

    return (
      <div className='ecomm-product-slider-card'>
        {this._renderImage()}
        {this._renderInfo()}
        <div className='action-container'><button onClick={this._onClick}>{this.props.button_text}</button></div>
      </div>
    );
  }

  private _renderImage(): JSX.Element | null {
    return (
      <div className='img-container'>
        <Image
          src={this.props.product.PrimaryImageUrl || ''}
          fallBackSrc={getFallbackImageUrl(this.props.product.ItemId, this.props.context.actionContext.requestContext.apiSettings)}
          altText={this.props.product.Name}
          gridSettings={this.props.gridSettings}
          loadFailureBehavior='empty'
        />
      </div>
    );
  }

  private _renderInfo(): JSX.Element | null {
    const DescriptionStyle = this.props.enableDescription ? 'info-description ellipsis' : 'no-display';
    const LocationStyle = this.props.enableLocation ? 'info-category' : 'no-display';
    const PriceStyle = this.props.enablePrice ? '' : 'no-display';

    return (
      <div className='info-container'>
        <p className={LocationStyle}>{this.props.category === undefined ? '' : this.props.category}</p>
        <p>{this.props.product.Name}</p>
        {!this.props.mobileMode && <p className={DescriptionStyle}>{this.props.product.Description}</p>}
        <p className={PriceStyle}>${this.props.product.Price}</p>
      </div>
    );
  }

  private _onClick = () => {
    if (typeof window !== 'undefined') {
      window.location.assign(getProductUrlSync(this.props.product, this.props.context.actionContext, undefined));
    }
  }
}

export default SliderCard;