import * as React from 'react';

import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';

interface ISliderFilter {
  category?: CategoryHierarchy;
  callback?: any;
}

/**
 *
 * EcommContentTemplate1View component
 * @extends {React.PureComponent<ISliderCard>}
 */
export class SliderFilter extends React.PureComponent<ISliderFilter> {
  public render(): JSX.Element | null {
    return <button onClick={this._handleOnClick}>{this.props.category?.Name}</button>;
  }

  private _handleOnClick = () => {
    this.props.callback(this.props.category?.RecordId);
  }
}

export default SliderFilter;